import moment from "moment";
import { format } from "date-fns";

export function getParamCaseInsensitive(param) {
  var urlParams = new URLSearchParams(window.location.search);
  for (let [key, value] of urlParams.entries()) {
    if (key.toLowerCase() === param.toLowerCase()) {
      return value;
    }
  }
  return null;
}

export function Callback(message) {
  try {
    var urlParams = new URLSearchParams(window.location.search);
    var hostUrl = urlParams.get("hostUrl") || process.env.REACT_APP_TARGET_POS_URL;
    var isMobile = getParamCaseInsensitive("ismobile");
    // window.chrome.webview.postMessage({ EVENT_TYPE: payload });
    console.log("Callback.Init");
    console.log(message);
    if (window.chrome.webview != null) {
      window.chrome.webview.postMessage(message);
      console.log("Callback.Success");
    }
  } catch (error) {
    console.error("Callback.Error: ", error);
  }
  console.log("message.isMobile::" + isMobile);
  if (isMobile == "true") {
    let isSuccess = false;
    try {
      console.log("executing callback for native apps.");
      window?.localCallback?.(JSON.stringify(message));
      console.log("completed callback for native apps.");
      isSuccess = true;
    } catch (error) {
      console.log("Callback.localCallback.Error: ", error);
    }

    if (!isSuccess) {
      try {
        console.log("executing callback for android app.");
        if (window.jsBridge && window.jsBridge.invokeAction)
          window.jsBridge.invokeAction(JSON.stringify(message));

        console.log("completed callback for android app.");
      } catch (error) {
        console.log("Callback.jsBridge.Error: ", error);
      }
    }
  } else if (isMobile == "false") {
    try {
      console.log("executing callback for web apps.");
      window.parent.postMessage(
        JSON.stringify(message),
        hostUrl
      );
      console.log("completed callback for web apps.");
    } catch (error) {
      console.log("Callback.webApps.Error: ", error);
    }
  }
}

export function SentenceCase(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function TitleCase(str) {
  str = str.toLowerCase().split(" ");
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

export function isEmpty(value) {
  return (
    value == null ||
    (typeof value === "string" && value.trim().length === 0) ||
    value.length == 0 ||
    typeof value == "undefined"
  );
}

export const WindowOpenCenter = ({ url, title, w, h }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : 100;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : 100;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  );
  return newWindow;
};

export function convertToUSD(value) {
  let dollarUSLocale = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }); // $148,000

  return value ? dollarUSLocale.format(value) : "$ 0.0";
}

export function startOfDayWithoutTimezone(value) {
  return format(value, "yyyy-MM-dd'T00:00:00'");
}

export function endOfDayWithoutTimezone(value) {
  return format(value, "yyyy-MM-dd'T23:59:59'");
}

export function checkThemeIdFormat(value){
  const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return guidRegex.test(value);
}

export const isMobile = () => new URLSearchParams(window.location.search).get("ismobile") === "true";

export const setAccessTokenInQueryParams = (newAccessToken) =>{
  const currentUrl = new URL(window.location.href);
  var urlParams = new URLSearchParams(currentUrl.search);

  if(currentUrl.searchParams.has('access_token')) {
    urlParams.set('access_token', newAccessToken);
    window.history.pushState({}, '', `${currentUrl.pathname}?${urlParams.toString()}`);
  }
}