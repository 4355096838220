import React from 'react';
import { Button, CircularProgress } from '@mui/material';

const LoadingSubmitButton = ({ loading, disabled, onClick, label }) => {
    return (
        <Button
            fullWidth
            style={{ minWidth: "200px", textTransform: "none" }}
            disabled={disabled || loading}
            variant="contained"
            onClick={onClick}
        >
            {label}
            {loading && (
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <CircularProgress
                        size="1.1rem"
                        style={{ color: "blue" }}
                    />
                </span>
            )}
        </Button>
    );
};

export default LoadingSubmitButton;
