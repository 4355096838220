import { COLORS, FONTS } from "./constant";

export class FormStyleCollection {

    static fieldSpacer: object = {
        marginTop: "20px"
    };


    static all: object = {
        root: {
            "& .MuiFormLabel-root": {
                fontSize: "large !important"
            },
            "& .MuiBox-root": {
                marginTop: "20px !important"
            }
        },
        autoComplete: {
            "& .MuiFormLabel-root": {
                fontSize: "large !important"
            },
            "& .MuiAutocomplete-input": {
                fontSize: "small !important"
            }
        },
        maskTextField: {
            "& .MuiFormLabel-root": {
                fontSize: "large !important"
            },
            "& .MuiInputBase-input": {
                fontSize: "small !important"
            }
        },
        paymentBox: {
            minHeight: "150px",
            fontSize: FONTS.labelSize,
            font: FONTS.default,
            border: "1px solid #E5E7E9",
            padding: "10px 20px",
            borderRadius: "10px",
            backgroundColor: "#F8F9F9",
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: "7px",
                    fontSize: FONTS.InputSize,
                },
            },
            "& .MuiFormControlLabel-label": {
                fontSize: FONTS.labelSize,
            },
            "& .MuiInputBase-input": {
                fontSize: FONTS.InputSize,
                padding: "8px 10px",
                backgroundColor: "white !important"
            },
            "& .MuiTableCell-root":{
                fontSize: FONTS.labelSize,
            },
            "& .MuiTableCell-head ":{
                backgroundColor: "#F2F3F4 !important",
                fontWeight:"bold"
            }

        },
        signatureBox: {
            minHeight: "150px",
            fontSize: FONTS.labelSize,
            font: FONTS.default,
            border: "1px solid #E5E7E9",
            padding: "10px 20px",
            borderRadius: "10px",
            backgroundColor: "#F8F9F9",
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: "7px",
                    fontSize: "medium",
                },
            },
            "& .MuiFormControlLabel-label": {
                fontSize: FONTS.labelSize,
            },
            "& .MuiInputBase-input": {
                fontSize: FONTS.InputSize,
                padding: "8px 10px",
                backgroundColor: "white !important"
            },
            "& .MuiTableCell-root":{
                fontSize: FONTS.labelSize,
            },
            "& .MuiTableCell-head ":{
                backgroundColor: "#F2F3F4 !important",
                fontWeight:"bold"
            }
        },
        paymentBoxManual: {
            "& .MuiFormControlLabel-label": {
                fontSize: FONTS.labelSize
            },
            "& .adyen-checkout__button--pay": {
                backgroundColor: COLORS.backgroundButton,
                textTransform:"capitalize !important",
                height: "36.5px",
                // textTransform:"uppercase",
            },
        },
        defaultFont: {
            fontSize: FONTS.labelSize,
            font: FONTS.default,
        }
    }
}