// signatureTypes.ts

import { TargetPayShopperSignatureResponse, ValidatedSignatureToken } from "../../types/types";

export interface SignatureState {
    terminalId: string;
    terminalList: any[];
    dataFetched: boolean;
    signatureFetched: boolean;
    dealerId?: number;
    errorMessage: string;
    isProcessing: boolean;
    signatureResponse: TargetPayShopperSignatureResponse
    validatedSignatureData: ValidatedSignatureToken;
    signatureToken: string;
}

// signatureInitialState.ts
export const initialState: SignatureState = {
  terminalId: "0",
  terminalList: [],
  dataFetched: false,
  signatureFetched: false,
  dealerId: undefined,
  errorMessage: "",
  isProcessing: false,
  signatureResponse: {
    dealerId: undefined,
    confirmflag: false,
    additionalInfo: '',
    isNonPaymentType: true,
    signature: '',
    status: '',
    CustomerID:''
  },
  validatedSignatureData: {
    dealerId: undefined,
    customerID: '',
    customerName: '',
    deviceName: '',
    machineName: '',
    userId: undefined,
    dealerInfo: ''
  },
  signatureToken: ""
};
  