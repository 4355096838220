import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { StyleCreator } from "../../helpers/stylecreator";
import { FormStyleCollection as formStyles } from "../../styles/form";
import { Callback, isEmpty, isMobile } from "../../utils/Util";
import { JWTDecode, TargetPayApi } from "../../utils/UtilTarget";
import TerminalDropdown from "../../common/components/TerminalDropdown";
import { TargetPayBaseResponse, TargetPayShopperSignatureResponse, TargetPaySignatureFailureResponse, TargetPaySignatureRequest } from "../../types/types";
import LoadingSubmitButton from "../../common/components/LoaderSubmitButton";
import { useValidateSignatureQuery, validateSignature } from "../../services/API/signatureApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { resetState, setErrorMessage, setIsProcessing, setSignatureFetched, setSignatureResponse, setSignatureToken, setTerminalId, setTerminalList, setValidatedSignatureData } from "../../features/nonPaymentSignature/signatureSlice";
export const SignatureRequest = () => {
    const classes = StyleCreator.makeStyles(formStyles.all);
    const dispatch = useDispatch();
    const [isQueryReady, setIsQueryReady] = useState(false);
    const {signatureToken,terminalId,dataFetched,signatureFetched, terminalList, errorMessage, signatureResponse, isProcessing, validatedSignatureData} = useSelector((state: RootState) => state.signature);
    
    useEffect(() =>{
        resetForm();
        getQueryParams();
    },[]);

    const getQueryParams = () => {
        var urlParams = new URLSearchParams(window.location.search);
        const signature_token = urlParams.get("signature_token");
        const access_token = urlParams.get("access_token");
        const refresh_token = urlParams.get("refresh_token");
        let obj: any = new Object();
        obj.Type = "Tab";
        obj.Tab = 'Capture Signature';
        let jsonString = JSON.stringify(obj);
        Callback(jsonString);
        setAuthTokens(access_token,refresh_token,signature_token);
    }

    const setAuthTokens = (access_token, refresh_token,signature_token) => {
        if(!isEmpty(refresh_token)) localStorage.setItem("refresh_token", refresh_token);
        if(!isEmpty(access_token)) localStorage.setItem("access_token",access_token); 
        readToken(signature_token);
    }
    const readToken = async (signature_token) => {
        try{
            if (!isEmpty(signature_token)) {
                dispatch(setSignatureToken(signature_token));
                getNonce(signature_token);
                setIsQueryReady(true);  // Set the flag to true to allow the query to run

            } else {
                dispatch(setErrorMessage("No Signature token provided"));
            }
        }
        catch(error){
            dispatch(setErrorMessage("Invalid/expired Signature token"));
        }
    }

    const { data, isError,isFetching, error} = useValidateSignatureQuery({signatureToken: signatureToken}, {
        skip: isEmpty(signatureToken) || !isQueryReady, // Skip the query if token is empty
        refetchOnMountOrArgChange: true
    });

    useEffect(() => {
        if (data && !isError && !isFetching) {
            dispatch(setValidatedSignatureData(data))
            fetchTerminals(data.dealerId);
            dispatch(validateSignature.util.resetApiState());
        } else if (isError || data === null) {
            resetForm();
            dispatch(setErrorMessage("Invalid/expired Signature token"));
        }
    }, [data, isError]);

    const fetchTerminals = async (dealer_id) => {
        try{
            if(!isNaN(dealer_id)){
                const response = await TargetPayApi("StoreTerminals",{ dealerId: dealer_id },"get");   
                if(isEmpty(response?.data)) dispatch(setErrorMessage("No Terminal Assigned"));
                else dispatch(setTerminalList(response.data || []));
            }
        }
        catch(error){
            toast.error("Error fetching terminals");
        }
    }

    const resetForm = () => {
        dispatch(resetState())
    }

    const isSignatureResponse = (response: TargetPayBaseResponse): response is TargetPayShopperSignatureResponse => {
        return (response as TargetPayShopperSignatureResponse).status !== undefined;
    };
    const isFailureResponse = (response: TargetPayBaseResponse): response is TargetPaySignatureFailureResponse => {
        return (response as TargetPaySignatureFailureResponse).response.data.status !== undefined && typeof (response as TargetPaySignatureFailureResponse).response.data.status !== "number";
    }

    const getNonce = (signatureToken: string) => {
        let nonce = JWTDecode(signatureToken, "Nonce");
        if (!isEmpty(nonce)) {
            nonce = nonce.split("").reverse().join("");
            sessionStorage.setItem("nonce", nonce);
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        const request: TargetPaySignatureRequest = { 
            POIID: terminalId, 
            dealerId: validatedSignatureData.dealerId, 
            machineName: validatedSignatureData.machineName,
            token: signatureToken, userId: validatedSignatureData.userId, 
            customerID: validatedSignatureData.customerID, 
            customerName: validatedSignatureData.customerName 
         }

        try{
            dispatch(setSignatureFetched(false));
            dispatch(setIsProcessing(true));
            const res: TargetPayBaseResponse = await TargetPayApi("RequestShopperSignature",request,"post","AdyenSignature",true);
            if(isSignatureResponse(res)){
                SetSignatureData(res);
            }
            else if(isFailureResponse(res)){
                if(res.response.data.status == 'Error') dispatch(setErrorMessage(res.response.data.additionalInfo));
                else SetSignatureData(res.response.data);   
            }
            else {
                dispatch(setErrorMessage("Unexpected error, Please try again later"))
            }

        }
        catch(error){
            toast.error("Unable to proceed the request");
        }
        finally{
            dispatch(setIsProcessing(false));
        }
    };

    const SetSignatureData = (response: TargetPayShopperSignatureResponse) => {
        response.additionalInfo = (response.confirmflag && isEmpty(response.signature)) 
            ? 'No Signature provided by shopper on terminal' 
            : response.additionalInfo;
        dispatch(setSignatureResponse(response))
        Callback(response);
    }

    const renderUI = () => {
        if (!isEmpty(errorMessage)) {
            return <Box style={{height: "100vh"}}>
                        <Box display="flex" alignItems="center" style={{border: "1px solid silver",padding: "5px 10px",marginTop: "20px" }}>
                            <h4>{errorMessage}</h4>
                        </Box>
                    </Box>

        } else if (!dataFetched) {
            return <Box display="flex" alignItems="center" style={{height: "100%" }}>
                        <CircularProgress/>
                        <span style={{ marginLeft: "10px" }}>Please wait. Processing.....</span>
                    </Box>
        } else if (dataFetched && terminalList.length > 0) {
          return <form onSubmit={handleSubmit}>
                    <Box>
                        <Tabs value="CaptureSignature" aria-label="basic tabs example" >
                            <Tab label="Capture Signature" value="CaptureSignature" />
                        </Tabs>
                        <Box
                        className={classes.signatureBox}
                        style={{ minWidth: !isMobile() ? "400px" : "100px", fontSize: '13px !important'}}
                        >
                        <Box>Customer: {validatedSignatureData.customerName} ({validatedSignatureData.customerID})</Box>
                        <Box style={{ border: "0px solid red" }}>

                            <Box style={{ marginTop: "20px" }}>
                            <TerminalDropdown 
                                terminalList={terminalList} 
                                terminalId={terminalId} 
                                setTerminalId={(id) => dispatch(setTerminalId(id))} 
                                disabled = {isProcessing}
                                defaultTerminalId={!isEmpty(JSON.parse(validatedSignatureData.dealerInfo).UserDefaultPOIID) ? JSON.parse(validatedSignatureData.dealerInfo).UserDefaultPOIID : validatedSignatureData.deviceName }
                            />

                            <Box style={{ marginTop: "20px", textAlign: "right" }}>
                                <LoadingSubmitButton
                                    loading={isProcessing}
                                    disabled={terminalId === "0" || terminalId === "" || terminalList.length === 0}
                                    onClick={handleSubmit}
                                    label={"Capture Signature"}
                                />                                    
                            </Box>
                            </Box>
                        </Box>
                            {
                                signatureFetched && (
                                    <Box style={{ marginTop: "15px" }}>
                                        <h6>
                                            <span style={{ color: signatureResponse.confirmflag && signatureResponse.status === 'Success' ? "green" : "red" }}>Status: {signatureResponse.status}</span>
                                        </h6>
                                        <Box style={{ maxWidth:"355px" }} >
                                            <span>
                                                {
                                                !isEmpty(signatureResponse.additionalInfo) ? 
                                                `Reason: ${signatureResponse.additionalInfo}` : 
                                                `Signature has been captured successfully`
                                                }
                                            </span>
                                        </Box>
                                    </Box>
                                )
                            }
                        </Box>
                        <Box
                        className={classes.paymentBoxManual}
                        style={{ textAlign: "right" }}
                        >
                        </Box>
                    </Box>
                </form>;
        } 
        return null; // Return null if no conditions are met
      };

    return (
        <Box>
            <Helmet>
              <title> Request Signature </title>
            </Helmet>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{ padding: "10px"}}
            >
                {renderUI()}
            </Box>
        </Box>
    )
}