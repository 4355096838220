import "@adyen/adyen-web/dist/adyen.css";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { initiateCheckout } from "../../features/adyen/paymentSlice";
import { getRedirectUrl } from "../../utils/redirect";
import { EverestApi, TargetPayApi } from "../../utils/UtilTarget";
import { isEmpty, SentenceCase } from "../../utils/Util";
let zipCode;
export const PaymentContainer = ({
  token,
  onPaymentStarted,
  onPaymentCompleted,
  saveCard,
}) => {

  return (
    <div id="payment-page">
      <div className="container">
        <Checkout
          token={token}
          onPaymentStarted={onPaymentStarted}
          onPaymentCompleted={onPaymentCompleted}
            saveCard={saveCard}
        />
      </div>
    </div>
  );
};



const Checkout = ({
  token,
  onPaymentStarted,
  onPaymentCompleted,
    saveCard,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.payment);

  const navigate = useNavigate();
  const paymentContainer = useRef(null);

  useEffect(() => {
    dispatch(initiateCheckout(token, saveCard));
  }, [dispatch, saveCard]);

  useEffect(() => {
    const { error } = payment;

    if (error) {
      navigate(`/status/error?reason=${error}`, { replace: true });
    }
  }, [payment, navigate]);

  useEffect(() => {
    const { config, session } = payment;
    let ignore = false;

    if (!session || !paymentContainer.current) {
      // initiateCheckout is not finished yet.
      return;
    }

    function GetValue(key) {
      try {
        let urlParams = new URLSearchParams(window.location.search);
        let returnValue = urlParams.get(key);
        if (key == "city" && !isEmpty(zipCode)) return zipCode.PrimaryCity;
        if (key == "state" && !isEmpty(zipCode)) return zipCode.State;
        if (key == "country" && !isEmpty(zipCode))
          return zipCode.Country.replace("CAN", "CA");
        if (key == "customername" && !isEmpty(returnValue))
          return SentenceCase(returnValue);
        if (isEmpty(returnValue)) return "";
        return returnValue;
      } catch (error) {
        return "";
      }
    }

    const createCheckout = async () => {
      let urlParams = new URLSearchParams(window.location.search);
      let zip = urlParams.get("zipcode");
      if (!isEmpty(zip)) {
        let obj = new Object();
        let objBody = new Object();

        obj.query = "Zipcode";
        objBody.zip = zip;

        obj.queryBody = JSON.stringify(objBody);

        zipCode = await TargetPayApi(
          "query",
          JSON.stringify(obj),
          "post",
          "Everest"
        );
      }

      const checkout = await AdyenCheckout({
        ...config,
        session,
          paymentMethodsConfiguration: {
          card: {
            hasHolderName: true, // Show the cardholder name field.
            holderNameRequired: true, // Mark the cardholder name field as required.
            billingAddressRequired: true,
            data: {
              holderName: GetValue("customername"),
              billingAddress: {
                street: GetValue("address"),
                city: GetValue("city"),
                stateOrProvince: GetValue("state"),
                postalCode: GetValue("zipcode"),
                country: GetValue("country"),
              },
            },
          },
        },
        beforeSubmit: (data, component, actions) => {
          onPaymentStarted();
          actions.resolve(data);
        },
        onPaymentCompleted: (response, _component) => {
          console.log(response);
          onPaymentCompleted(response.resultCode, null);
        },
        onError: (error, _component) => {
          console.log(error);
          onPaymentCompleted("failure", error.message);
        },
      });

      // The 'ignore' flag is used to avoid double re-rendering caused by React 18 StrictMode
      // More about it here: https://beta.reactjs.org/learn/synchronizing-with-effects#fetching-data
      if (paymentContainer.current && !ignore) {
        setIsLoading(false);
        checkout.create("dropin").mount(paymentContainer.current);
      }
    };

    createCheckout();

    return () => {
      ignore = true;
    };
  }, [payment, navigate]);

  return (
    <div className="payment-container">
      <div ref={paymentContainer} className="payment">
        {isLoading && (
          <span>
            <h5>Please wait......</h5>
          </span>
        )}
      </div>
    </div>
  );
};

export default PaymentContainer;
