import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useAppDispatch } from "../../../app/hooks";
import { logout } from "../../../features/auth/authSlice";
import { Stack } from "rsuite";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DealerProfile, JWTDecode } from "../../../utils/UtilTarget";
import { setDealerId } from "../../../features/dealer/dealerSlice";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const Header = (props: any) => {
  const theme = useTheme(); // Access the theme
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { handleDrawerToggle } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [loggedInUserName, setloggedInUserName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");

  useEffect(() => {
    setloggedInUserName(
      JWTDecode(localStorage.getItem("access_token"), "name")
    );
    getCompanyName();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const iconAccount = document.getElementById("iconAccount");
    setAnchorEl(iconAccount);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(setDealerId(""));
    localStorage.clear();
    navigate("/");
  };

  async function getCompanyName() {
    setCompanyName(await DealerProfile("companyName"));
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={6} style={{ fontSize: "large" }}>
            {theme.title} {companyName ? ` - ${companyName}` : ""}
          </Grid>
          <Grid item style={{ flexGrow: "1" }}></Grid>
          <Grid item xs={6}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ backgroundColor: "transparent" }}
                color="inherit"
              >
                <Typography>Hello, {loggedInUserName}</Typography>
                <KeyboardArrowDownIcon />
                <AccountCircle id="iconAccount" />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ "aria-labelledby": "basic-button" }}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
