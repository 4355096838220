import Router from "./routes";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ThemeProvider,
  CssBaseline,
  Box,
  CircularProgress,
} from "@mui/material";
import { TargetPayApi } from "./utils/UtilTarget";
import React, { useEffect } from "react";
import { generateTheme } from "./utils/theme";
import { isEmpty } from "./utils/Util";
import { ThemeSettings } from "./types/types";

console.log(`App is Running in ${process.env.REACT_APP_ENV} Environment`);

// Function to fetch theme settings from API
const fetchThemeSettings = async (): Promise<ThemeSettings> => {
  const hostNameUrl = window.location.hostname; // Host Name URL

  const response = await TargetPayApi(
    `GetMerchantBranding?merchantURL=${hostNameUrl}`,
    null,
    "get",
    "MerchantBranding"
  );

  return response;
};

const App = () => {
  // Default Theme values
  const [theme, setTheme] = React.useState(
    generateTheme({
      title: "Welcome to PerseusPay",
      headerColor: "#1976d2",
      buttonColor: "#1976d2",
      menuColor: "#3366ff",
      loginLogoPath: "",
      headerLogoPath: "",
    })
  );

  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const loadTheme = async () => {
      try {
        const settings = await fetchThemeSettings(); // Fetch theme data
        if (!isEmpty(settings)) {
          setTheme(generateTheme(settings)); // Apply fetched theme
        }
      } catch (error) {
        console.error("Error fetching theme settings:", error);
      } finally {
        setLoading(false);
      }
    };

    loadTheme();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HelmetProvider>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
        <Router />
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default App;
