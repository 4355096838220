import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { isEmpty } from "../../utils/Util";
import { useEffect } from "react";

const TerminalDropdown = ({terminalList, terminalId, setTerminalId, disabled = false, label = 'Terminal', defaultTerminalId}) => {

    const CheckActiveTerminal = (row) => {
        let diffMins = CheckActiveTermininalInMin(row);
        let res = `Now`;
        if (diffMins > 24 * 60) return "";
        if (diffMins > 0) res = `${diffMins}`;
        if (diffMins < 2) return `(Active)`;
        if (diffMins < 60) return `(Active ${res}m)`;
        return "";
      };
    
      const CheckActiveTermininalInMin = (row) => {
        if (!row.lastActivityAt) return 9999;
        const lastActivityDate = Date.parse(row.lastActivityAt);
        const diffMs = Date.now() - lastActivityDate; 
        return Math.round(((diffMs % 86400000) % 3600000) / 60000); 
      };

      useEffect(() => {
        SetLastUsedTerminal()
      }, [defaultTerminalId,terminalList])
      
      const SetLastUsedTerminal = () => {
        if(!isEmpty(defaultTerminalId)){
            const checkOption = terminalList.find(x => x.id === defaultTerminalId);
            if(checkOption) setTerminalId(defaultTerminalId);
        }
      }

      return (
        <FormControl fullWidth>
          <InputLabel id="terminal-label">{label}</InputLabel>
          <Select
            fullWidth
            labelId="terminal-label"
            label={label}
            id="filter-type-select"
            value={terminalId}
            onChange={(e) => setTerminalId(e.target.value)}
            disabled = {disabled}
          >
            <MenuItem value="0">Choose One</MenuItem>
            {terminalList.map((row, index) => (
              <MenuItem value={row.id} key={index}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: "bottom" }}>
                        {CheckActiveTermininalInMin(row) > 60 ? (
                          <ClearOutlinedIcon style={{ fontSize: "15", color: "red", marginRight: "10px" }} />
                        ) : (
                          <CheckIcon
                            style={{
                              fontSize: "15",
                              color: CheckActiveTermininalInMin(row) < 10 ? "green" : "orange",
                              marginRight: "10px",
                            }}
                          />
                        )}
                      </td>
                      <td>{row.terminalName}</td>
                      <td>
                        <Box style={{ marginLeft: "8px", fontSize: "12px", color: "gray" }}>
                          {CheckActiveTerminal(row)}
                        </Box>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
};

export default TerminalDropdown;