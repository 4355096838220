export enum PaymentMethodType {
  Visa = "visa",
  Maestro = "maestro",
  MasterCard = "mc",
  AmericanExpress = "amex",
  Discover = "discover",
  Diners = "diners"
}

export enum PaymentStatus {
  Success = "Success",
  Failed = "Failed",
  Pending = "Pending",
  Refunded = "Refunded",
  Cancelled = "Cancelled",
  PendingRefund = "Pending Refund",
  Authorised = "Authorised",
  LinkSent = "Link Sent",
  CardSaved = "Card Saved"
}

export enum CardType {
  CreditSale = "CreditSale",
  DebitSale = "DebitSale"
}